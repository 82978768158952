<template>
  <div :class="[
    currentRoute === 'home'
      ? 'dark:bg-gradient-to-b dark:from-dark_theme_base_300 dark:to-dark_theme_base_200 bg-gradient-to-b from-gray-300 to-gray-200'
      : 'dark:bg-gradient-to-b dark:from-dark_theme_base_300 dark:to-dark_theme_base_100 bg-gradient-to-b from-gray-300 to-gray-100 ',
    'navbar flex flex-col  sm:flex-row',
  ]">
    <div class="flex-1 items-center">
      <div class="hidden sm:block">
        <DarkMode type="desktop" />
      </div>
      <NuxtLink class="normal-case text-xl p-3" to="/">
        <span
          class="dark:text-dark_theme_base_content text-black hover:text-blue-500 dark:hover:text-white transition-all duration-300 ease-out">Nikola
          Ivanović</span>
      </NuxtLink>
    </div>
    <div class="flex-none">
      <div class="flex">
        <div class="block sm:hidden mr-3">
          <DarkMode type="mobile" />
        </div>
        <div v-for="link in navLinks" :class="[link.text === 'CV' && '', 'mr-2 group']" :title="link.tooltipText">
          <div class="flex flex-col !bg-transparent p-2">
            <template v-if="link.url === '/files/cv/CV - Nikola Ivanovic.pdf'">
              <a class="!pb-0 md:p-3 hover:!text-blue-500 rounded-md transition-all duration-300 ease-out"
                :href="link.url" :download="link.downloadAttr" aria-label="My CV">
                <span
                  class="dark:!text-dark_theme_base_content group-hover:dark:!text-white rounded-md transition-all duration-300 ease-out">
                  <!-- {{ link.text }} -->
                  <FontAwesomeIcon :icon="['fas', 'file']"
                    class="ml-2 dark:text-dark_theme_base_content text-gray-700 hover:dark:text-white hover:text-blue-500 transition-colors duration-300 ease-out"
                    size="xl" />
                </span>
              </a>
            </template>
            <template v-else>
              <NuxtLink class="!pb-0 md:p-3" :to="link.url">
                <span :class="[
                  currentRoute === link.text.toLowerCase() &&
                  'dark:!text-white !text-blue-500',
                  '!text-black dark:!text-dark_theme_base_content group-hover:dark:!text-white group-hover:!text-blue-500 transition-all duration-300 ease-out',
                ]">{{ link.text }}</span>
                <div v-if="currentRoute === link.text.toLowerCase()" class="h-[2px] w-[100%] bg-blue-500 mt-2"></div>
                <div v-if="currentRoute !== link.text.toLowerCase()"
                  class="h-[2px] mt-2 mx-auto bg-gray-500 w-[0px] transition-all duration-500 ease-out transform group-hover:w-[100%] group-hover:bg-blue-500">
                </div>
              </NuxtLink>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { navLinks } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const router = useRouter()

const currentRoute = computed(() => {
  if (router.currentRoute.value.name === 'index') {
    return 'home'
  }
  return router.currentRoute.value.name
})
</script>

<style scoped>
.tooltip.tooltip-prefered {
  --tooltip-color: rgba(59, 131, 246, 0.467);
  --tooltip-text-color: white;
}

a:hover {
  color: white !important;
}
</style>
